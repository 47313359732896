import { HttpError } from './http-error'

export default function getAPI() {

  // Default Headers
  const Headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }

  // Default cache
  const cache = 'no-store'

  const get = async (route: string, options: any = {}) => {
    return await handleAPIResponse(await fetch('/api' + route, { Headers, cache, ...options, method: 'GET' }))
  }

  const post = async (route: string, body: any = {}, options: any = {}) => {
    return await handleAPIResponse(await fetch('/api' + route, { Headers, cache, ...options, body: JSON.stringify(body), method: 'POST' }))
  }

  const put = async (route: string, body: any = {}, options: any = {}) => {
    return await handleAPIResponse(await fetch('/api' + route, { Headers, cache, ...options, body: JSON.stringify(body), method: 'PUT' }))
  }

  const patch = async (route: string, body: any = {}, options: any = {}) => {
    return await handleAPIResponse(await fetch('/api' + route, { Headers, cache, ...options, body: JSON.stringify(body), method: 'PATCH' }))
  }

  const upload = async (route: string, body: any = {}, options: any = {}) => {
    return await handleAPIResponse(await fetch('/api' + route, { ...options, body: body, method: 'POST' }))
  }

  const del = async (route: string, body: any = {}, options: any = {}) => {
    return await handleAPIResponse(await fetch('/api' + route, { Headers, cache, ...options, body: JSON.stringify(body), method: 'DELETE' }))
  }

  async function handleAPIResponse(response: Response): Promise<any> {
    if (response.ok || response.status === 200) {
      try {
        return await response.clone().json()
      } catch (error) {
        try {
          return await response.text()
        } catch (error2) {
          console.error('API Error: ' + response.status + ' - ' + response.statusText)
          return response
        }
      }
    }
    throw new HttpError(response.status, 'API Error: ' + response.status + ' - ' + response.statusText)
  }
  return { get, post, put, patch, upload, del }
}
