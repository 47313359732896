import(/* webpackMode: "eager" */ "/vercel/path0/components/i18n/lang-context-client.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["LangMenuFlatHTML"] */ "/vercel/path0/components/i18n/lang-toggle.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Assistant"] */ "/vercel/path0/components/jbda/assistant.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CookieConsentLink"] */ "/vercel/path0/components/jbda/cookies-dialog.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Header"] */ "/vercel/path0/components/jbda/header.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["LBAction"] */ "/vercel/path0/components/jbda/lb/lb-action.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Section"] */ "/vercel/path0/components/jbda/section.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/link.js");
