/**
 * HTTP Error class. Extends the Base class "Error"
 * Add an "errorCode" attribute to the classic Error class
 */
export class HttpError extends Error {

  public errorCode: number

  constructor(errorCode: number, message: string) {
    super(message)
    this.errorCode = errorCode
  }

  public toString = () => 'ERROR: [' + this.errorCode + '] ' + this.message

}
